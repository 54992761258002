// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItemIcon-module-container--bix9f{width:24px;height:24px;image-rendering:pixelated}", "",{"version":3,"sources":["webpack://./src/components/app/ItemIcon/ItemIcon.module.scss"],"names":[],"mappings":"AAAA,kCACE,UAAA,CACA,WAAA,CACA,yBAAA","sourcesContent":[".container {\n  width: 24px;\n  height: 24px;\n  image-rendering: pixelated;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ItemIcon-module-container--bix9f"
};
export default ___CSS_LOADER_EXPORT___;
