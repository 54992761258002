// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BattleGenOptionTooltip-module-container--Z7Qa3{position:relative;max-width:165px;text-align:center;white-space:normal;-webkit-user-select:none;-moz-user-select:none;user-select:none}", "",{"version":3,"sources":["webpack://./src/components/app/BattleGenOptionTooltip/BattleGenOptionTooltip.module.scss"],"names":[],"mappings":"AAAA,gDACE,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,gBAAA","sourcesContent":[".container {\n  position: relative;\n  max-width: 165px;\n  text-align: center;\n  white-space: normal;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BattleGenOptionTooltip-module-container--Z7Qa3"
};
export default ___CSS_LOADER_EXPORT___;
