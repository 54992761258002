// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PokePresetOptionTooltip-module-container--Mek2L{position:relative;max-width:200px;white-space:pre-wrap;-webkit-user-select:none;-moz-user-select:none;user-select:none}", "",{"version":3,"sources":["webpack://./src/components/app/PokePresetOptionTooltip/PokePresetOptionTooltip.module.scss"],"names":[],"mappings":"AAAA,iDACE,iBAAA,CACA,eAAA,CACA,oBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,gBAAA","sourcesContent":[".container {\n  position: relative;\n  max-width: 200px;\n  white-space: pre-wrap;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "PokePresetOptionTooltip-module-container--Mek2L"
};
export default ___CSS_LOADER_EXPORT___;
