// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverlayModule-module-container--PDV8G{-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:grabbing}", "",{"version":3,"sources":["webpack://./src/components/layout/RackGrid/OverlayModule.module.scss"],"names":[],"mappings":"AAEA,uCAEE,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CACA,eAAA","sourcesContent":["// @use 'mixins/aria';\n\n.container {\n  // transform-origin: top left;\n  user-select: none;\n  cursor: grabbing;\n\n  // @include aria.outline(\n  //   $offset: 4px,\n  //   $radius: 100px,\n  //   $z-index: -1,\n  // );\n\n  // &::after {\n  //   opacity: 0.88;\n  // }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OverlayModule-module-container--PDV8G"
};
export default ___CSS_LOADER_EXPORT___;
