// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CircularBar-module-container--jyOR7{width:100%}.CircularBar-module-pathArc--BtFoC{stroke:currentColor;stroke-linecap:round;opacity:.24;transition:stroke 250ms ease}.CircularBar-module-valueArc--qyhCw{stroke:currentColor;stroke-linecap:round;transition:stroke 250ms ease, stroke-dashoffset 250ms ease}", "",{"version":3,"sources":["webpack://./src/components/ui/CircularBar/CircularBar.module.scss","webpack://./src/styles/mixins/_transition.scss"],"names":[],"mappings":"AAEA,qCACE,UAAA,CAGF,mCACE,mBAAA,CACA,oBAAA,CACA,WAAA,CC0BE,4BAAA,CDtBJ,oCACE,mBAAA,CACA,oBAAA,CCoBE,0DAAA","sourcesContent":["@use 'mixins/transition';\n\n.container {\n  width: 100%; // fix for 0 width in flex container\n}\n\n.pathArc {\n  stroke: currentColor;\n  stroke-linecap: round;\n  opacity: 0.24;\n  @include transition.apply(stroke);\n}\n\n.valueArc {\n  stroke: currentColor;\n  stroke-linecap: round;\n  @include transition.apply(stroke, stroke-dashoffset);\n}\n","////\n/// @group transition\n/// @author Keith Choison\n////\n\n@use 'sass:list';\n@use 'sass:meta';\n@use 'config/animations';\n\n@mixin apply($props...) {\n  $transitions: ();\n\n  @for $i from 1 through list.length($props) {\n    $prop: list.nth($props, $i);\n\n    $name: $prop;\n    $duration: animations.$transition-duration;\n    $function: animations.$transition-function;\n\n    @if meta.type-of($prop) == 'list' and list.length($prop) > 1 {\n      $name: list.nth($prop, 1);\n\n      @if list.nth($prop, 2) {\n        $duration: list.nth($prop, 2);\n      }\n\n      @if list.length($prop) > 2 and list.nth($prop, 3) {\n        $function: list.nth($prop, 3);\n      }\n    }\n\n    $transitions: list.append($transitions, '#{$name} #{$duration} #{$function}');\n  }\n\n  @if list.length($transitions) > 0 {\n    transition: #{list.zip($transitions)};\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CircularBar-module-container--jyOR7",
	"pathArc": "CircularBar-module-pathArc--BtFoC",
	"valueArc": "CircularBar-module-valueArc--qyhCw"
};
export default ___CSS_LOADER_EXPORT___;
