// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableGrid-module-container--ysUav{display:grid;align-items:center}.TableGrid-module-item--IG3h5{grid-area:span 1/span 1;text-align:center}.TableGrid-module-item--IG3h5.TableGrid-module-left--VGuUy{text-align:left}.TableGrid-module-item--IG3h5.TableGrid-module-right--ArEWu{text-align:right}.TableGrid-module-item--IG3h5.TableGrid-module-small--BICcp{font-size:8px}.TableGrid-module-header--udDVl{font-weight:500;text-transform:uppercase;-webkit-user-select:none;-moz-user-select:none;user-select:none}.TableGrid-module-header--udDVl.TableGrid-module-light--BxIWW{color:rgba(0,0,0,.75)}.TableGrid-module-header--udDVl.TableGrid-module-dark--GPN1f{color:rgba(255,255,255,.75)}", "",{"version":3,"sources":["webpack://./src/components/layout/TableGrid/TableGrid.module.scss"],"names":[],"mappings":"AAGA,mCACE,YAAA,CACA,kBAAA,CAGF,8BACE,uBAAA,CACA,iBAAA,CAEA,2DACE,eAAA,CAGF,4DACE,gBAAA,CAGF,4DACE,aAAA,CAIJ,gCACE,eAAA,CACA,wBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,gBAAA,CAEA,8DACE,qBAAA,CAGF,6DACE,2BAAA","sourcesContent":["@use 'config/colors';\n@use 'functions/color';\n\n.container {\n  display: grid;\n  align-items: center;\n}\n\n.item {\n  grid-area: span 1 / span 1;\n  text-align: center;\n\n  &.left {\n    text-align: left;\n  }\n\n  &.right {\n    text-align: right;\n  }\n\n  &.small {\n    font-size: 8px;\n  }\n}\n\n.header {\n  font-weight: 500;\n  text-transform: uppercase;\n  user-select: none;\n\n  &.light {\n    color: color.alpha(colors.$black, 0.75);\n  }\n\n  &.dark {\n    color: color.alpha(colors.$white, 0.75);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TableGrid-module-container--ysUav",
	"item": "TableGrid-module-item--IG3h5",
	"left": "TableGrid-module-left--VGuUy",
	"right": "TableGrid-module-right--ArEWu",
	"small": "TableGrid-module-small--BICcp",
	"header": "TableGrid-module-header--udDVl",
	"light": "TableGrid-module-light--BxIWW",
	"dark": "TableGrid-module-dark--GPN1f"
};
export default ___CSS_LOADER_EXPORT___;
